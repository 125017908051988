@import "config";

.ant-picker-panel-container {
  background: $white !important;
}

// Collapse

.hide-collapse-header .ant-collapse-header {
  display: none !important;
}

.hide-collapse-header .ant-collapse-content-box {
  padding: 0 !important;
}

//Alert
.ant-alert-icon {
  font-size: 16px !important;
}

.ant-alert {
  display: flex;
  align-items: center;
}

.ant-alert-description {
  font-size: 12px;
}

// Custom Table
.separate-rows-table {
  .ant-table {
    background: transparent;
  }

  .ant-table-body > table {
    border-collapse: separate;
    border-spacing: 0 19px;
    margin-top: -12px;
  }

  .ant-table-cell {
    background-color: $white !important;
  }
}

.blue-header-table {
  .ant-table-thead > tr th {
    background-color: $blue !important;
    color: $white !important;

    &.ant-table-column-sort::before {
      background-color: #e0d9d4 !important;
    }

    .ant-table-column-sorter {
      color: rgba(255, 255, 255, 0.1);

      & .active {
        color: $white;
      }
    }

    &:hover {
      .ant-table-column-sorter {
        color: rgba(255, 255, 255, 0.5);
      }

      &.ant-table-column-has-sorters::before {
        background-color: #e0d9d4 !important;
      }
    }
  }
}

.custom-rows-expanded {
  .ant-table-expanded-row {

    > td {
      background-color: rgba(218, 218, 218, 0.40) !important;
    }
  }
}

.ant-form-item-required {
  align-items: flex-start !important;
  flex-direction: row-reverse;
  font-weight: 700;

  &::before {
    margin-left: 4px;
  }

  &::after {
    width: 0;
    margin: 0 !important;
  }
}

// Calculer une prime
.calculateur-tabs {
  .ant-tabs-tab {
    &-btn {
      @apply text-gray-300 !important;
      @apply text-xl !important;

      &:hover {
        @apply text-gray-950 !important;
      }
    }

    &-active {
      .ant-tabs-tab-btn {
        @apply text-gray-950 !important;
      }
    }
  }
}